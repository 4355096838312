//import * as azureFunctions from './azureDevConfig';
import * as azureFunctions from './azureConfig';

const siteURL = "https://mpapi.logitgroup.com/api/";
const participantUrl= "https://zampparticipant.zamplia.com/";
//const participantUrl = "http://localhost:3000/";
const appConfig ={
  siteURL : siteURL,
  participantUrl : participantUrl,
  azureFunctions : azureFunctions
}

export default appConfig