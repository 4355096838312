import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import StudyTerminate from './components/terminateFolder'
import Terminate from './components'
class App extends Component {

  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    let viewHtml ="";

    viewHtml = <Router>
      <div>
      <Switch>
      <Route component={StudyTerminate} exact path="/:key?"/>
      {/* <Route component={Terminate} exact path="/"/> */}
      </Switch>
      </div>
    </Router>

    return (
      <div className="App">
        <header className="App-header">
        {viewHtml}
        </header>
      </div>
    );
  }
}

export default App;
