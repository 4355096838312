import {getRedirectAppMessageApi, noSurveryRequiredByUserApi,
     updateParticipantApi, trackParticipantsApi,
      vendorRedirectUpdateApi, attemptRouterChainSurveyApi,
      endRouterChainSurveyApi,
      clientUpdateRedirectUpdateApi,
      getRedirectUrlForUserAttemptApi} from '../api/terminateApi';

export const setUpdateParticipant = (updateParticipantResp) => {
    return {
        type: "SET_UPDATE_PARTICIPANT_RESPONSE",
        updateParticipantResp

    }
}

export const setAttemptRouterChainSurvey = (attemptRouterChainSurvey) => {
    return {
        type: "SET_ATTEMPT_ROUTER_CHAIN_RESPONSE",
        attemptRouterChainSurvey
    }
}

export const setEndRouterChainSurveyResponse = (endRouterChainSurvey) => {
    return {
        type: "SET_END_ROUTER_CHAIN_SURVEY_RESPONSE",
        endRouterChainSurvey
    }
}

export const setRedirectAppMessage = (uiContent) => {
    return {
        type: "SET_UI_CONTENT",
        uiContent
    }
}

export const trackParticipantsResponse = (response) => {
    return {
        type: "SET_TRACKING_RESPONSE",
        response
    }
}

export const updateParticipant = (data) => {
    return dispatch => {
        updateParticipantApi(data).then((result) => {
            dispatch(setUpdateParticipant(result));
        });
    }
}
export const getRedirectUrlForUserSurveyAttempt = (data) => {
    return dispatch => {
		getRedirectUrlForUserAttemptApi(data).then((result) => {
            dispatch(setUpdateParticipant(result));
		})
    }
}


export const attemptRouterChainSurvey = (data) => {
    return dispatch => {
        attemptRouterChainSurveyApi(data).then((result) => {
            dispatch(setAttemptRouterChainSurvey(result));
        });
    }
}

export const getUIContent = (data) => {
    return dispatch => {
        getRedirectAppMessageApi(data).then((result) => {
            dispatch(setRedirectAppMessage(result));
        });
    }
}

export const trackParticipants = (url) => {
    return dispatch => {
        trackParticipantsApi(url).then((result) => {
            dispatch(trackParticipantsResponse(result));
        });
    }
}



export const endRouterChainSurvey = (originPID) => {
    return dispatch => {
        endRouterChainSurveyApi(originPID).then((result) => {
            dispatch(setEndRouterChainSurveyResponse(result));
        });
    }
}

export const vendorRedirectUpdate = (data, redirectVendor) => {
    return dispatch => {
        vendorRedirectUpdateApi(data, redirectVendor).then((result) => {});
    }
}

export const updateClientAppRedirectUpdate = (data, url) => {
    return dispatch => {
        clientUpdateRedirectUpdateApi(data, url).then((result) => {});
    }
}