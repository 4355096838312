import React from "react";
import { Card, Row, Col, Button } from "antd";
import "./style.css";
import { FormattedMessage } from "react-intl";

export const getUserSurveyList = (
  surveyList,
  clientType,
  isFirstAttempt,
  onMultipleAttemptClick,
  parentPID
) => {
  let surveyListShow = "";

  if(clientType == 11 || clientType == 2){
      surveyListShow = surveyList.map((item, i) => {
              return <ul className="loop-surveys-list">
              <li className="short-title">{i+1}) {item.SurveyName}</li>
              <li>LOI : {item.SurveyLength}</li>
              <li>IR : {item.Ir}</li>
              <li>
                <Button
                  type="primary"
                  shape="round"
                  className="btn-zamp-color"
                  onClick={() => onMultipleAttemptClick(item, clientType, isFirstAttempt, parentPID)}
                  size="default"
                >
                  <FormattedMessage id="Attempt" />
                </Button>
              </li>
            </ul>
      });
  }

  let showHtml = (
    <div>
      <Row gutter={16}>
        <Col className="gutter-row" span={20} offset={2}>
          <Card title="" className="loop-surveys-div">
            {surveyListShow}
          </Card>
        </Col>
      </Row>
    </div>
  );

  let showParticipantPage = (
    <div>
      <div>{showHtml}</div>
    </div>
  );

  return showParticipantPage;
};
