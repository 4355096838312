import React, { Component } from 'react';
import { connect } from 'react-redux';

class Terminate extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }

  }

  render() {
    
    return (
      <div>
         Terminate App Is Running
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
   
  }
}

export default connect(mapStateToProps)(Terminate);
